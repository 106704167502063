export const store = holiday => {
    return window.axios.post("/api/holiday", holiday);
};

export const index = params => {
    return window.axios.get("/api/holiday", { params });
};

export const update = holiday => {
    return window.axios.put("/api/holiday/" + holiday.id, holiday);
};

export const destroy = holiday => {
    return window.axios.delete("/api/holiday/" + holiday.id);
};
