<template>
    <footer>
        <div class="wrapper-container">
            <div class="logo-container white--text">
                <img
                    class="logo-container__img"
                    src="/icons/logo-white.svg"
                    alt=""
                />
            </div>
            <div class="footer-container">
                <div class="footer-first-container white--text">
                    <div class="footer-first-container__mail">
                        <a :href="'mailto:'+contactEmail" class="white--text main-footer__mail-link">
                            <span class="footer-first-container__mail-text">{{ contactEmail }}</span>
                        </a>
                    </div>
                </div>
                <div class="footer-second-container white--text">
                    <div class="footer-second-container__action">
                        <div class="footer-second-container__links">
                            <p> {{ $t('general.ready-to-go') }}</p>
                            <h4>{{ $t('general.lets-start') }}</h4>
                        </div>
                        <div class="footer-second-container__button">
                            <router-link v-if="!$auth.user()" :to="{ name: 'sign-up' }">
                                <button>{{ $t('general.get-started-for-free') }}</button>
                            </router-link>
                            <router-link v-if="$auth.user()" :to="{ name: 'dashboard.index' }">
                                <button>{{ $t('general.dashboard') }}</button>
                            </router-link>

                            <div class="footer-second-container__mob-email">
                                <a :href="'mailto:'+contactEmail" class="white--text main-footer__mail-link-mob">
                                    <span class="footer-first-container__mail-text-mob">{{ contactEmail }}</span>
                                </a>
                            </div>

                            <div class="footer-second-container__mob-phone">
                                <a :href="'tel:'+contactTel" class="white--text main-footer__phone-link-mob">
                                    <span class="footer-third-container__phone-num-mob">{{ contactTel }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="footer-second-container__action">
                        <div class="footer-second-container__inner container">
                            <ul class="third-footer__media-list">
                                <li class="third-footer__media-item">
                                    <a href="https://www.facebook.com/NomadicSoftLLC" class="main-footer__media-link">
                                        <img src="/icons/facebook.svg" alt="#" class="main-footer__media-icon">
                                    </a>
                                </li>
                                <li class="third-footer__media-item">
                                    <a href="https://www.linkedin.com/company/nomadic-soft" class="main-footer__media-link">
                                        <img src="/icons/linkedin.svg" alt="#" class="main-footer__media-icon">
                                    </a>
                                </li>
                                <li class="third-footer__media-item">
                                    <a href="https://twitter.com/nomadicsoftio" class="main-footer__media-link">
                                        <img src="/icons/twitter.svg" alt="#" class="main-footer__media-icon">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="footer-second-container-logo-mob">
                        <div class="logo-container-mob white--text">
                            <img
                                class="logo-container__img-mob"
                                src="/icons/logo-white.svg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="footer-third-container white--text">
                    <div class="footer-third-container__phone">
                        <a :href="'tel:'+contactTel" class="white--text main-footer__phone-link">
                            <span class="footer-third-container__phone-num">{{ contactTel }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </footer>
</template>

<script>
export default {
    computed: {
        contactEmail: function () {
            return process.env.APP_CONTACT_EMAIL
                ? process.env.APP_CONTACT_EMAIL
                : "hello@nomadicsoft.io";
        },
        contactTel: function () {
            return process.env.APP_CONTACT_TEL
                ? process.env.APP_CONTACT_TEL
                : "+1 (844) 961-4030";
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/mixins";

ul {
    list-style-type: none;
}

li {
    list-style-type: none;
}

.wrapper-container {
    display: flex;
    flex-direction: column;
    padding: 34px 100px;
    height: 449px;
    margin-top: 80px;
    background: #232020;

    @include lg-block() {
        padding: 34px 24px;
    }

    .logo-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        @include lg-block() {
            display: none;
        }
    }

    .footer-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        p, h4, a {
            text-align: center;
        }

        .footer-first-container {
            display: flex;
            flex-direction: column;
            width: 60%;
            justify-content: center;

            @include lg-block() {
                display: none;
            }
        }

        .footer-second-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            .footer-second-container__mob-email {

                @include lg-block() {
                    margin-top: 42px;
                }
            }

            .footer-second-container__mob-phone {

                @include lg-block() {
                    margin-top: 20px;
                }
            }

            .footer-second-container__action {

                .footer-second-container__links {
                    p {
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 27px;
                        margin-bottom: 24px;

                        @include lg-block() {
                            font-size: 16px;
                            margin-bottom: 12px;
                        }
                    }

                    h4 {
                        font-size: 40px;
                        font-weight: 700;
                        line-height: 49px;

                        @include lg-block() {
                            font-size: 28px;
                        }
                    }
                }

                .footer-second-container__button {
                    margin-top: 33px;
                    text-align: center;

                    @include lg-block() {
                        margin-top: 23px;
                    }

                    button {
                        background: #1e5cba;
                        border-radius: 10px;
                        padding: 20px 80px;
                        font-size: 24px;
                        color: #fff;

                        @include lg-block() {
                            font-size: 24px;
                        }

                        &:hover {
                            background: #154286;
                            transition: all .3s;
                        }

                        @include sm-block() {
                            display: block;
                            margin: 0 auto;
                            padding: 10px 40px;
                            font-size: 24px;
                        }
                    }
                }

                .footer-second-container__inner {
                    width: 30%;
                    display: flex;
                    justify-content: center;

                    @include lg-block() {
                        width: 30%;
                    }

                    @include sm-block() {
                        width: 40%;
                    }

                    ul {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        margin-top: 100px;
                        padding:0 !important;

                        @include lg-block() {
                            margin-top: 30px;
                            justify-content: space-evenly;
                            width: 70%;
                        }

                        @include sm-block() {
                            justify-content: center;
                            width: 70%;
                        }

                        li:nth-child(2) {
                            @include sm-block() {
                                padding: 0 5vw;
                            }
                        }
                    }
                }
            }

            .footer-second-container-logo-mob {
                display: none;

                @include lg-block() {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 25px;
                }

                .logo-container-mob {
                    display: flex;
                    justify-content: center;
                }

                .logo-container__img-mob {
                    @include lg-block() {
                        width: 70%
                    }
                }

            }
        }

        .footer-third-container {
            display: flex;
            width: 60%;
            justify-content: flex-end;
            align-items: center;

            @include lg-block() {
                display: none;
            }
        }
    }

    .footer-first-container__mail-text, .footer-third-container__phone-num {
        font-size: 26px;
        font-weight: 400;
        line-height: 32px;

        @include lg-block() {
            display: none;
        }
    }

    .footer-first-container__mail-text-mob, .footer-third-container__phone-num-mob {
        display: none;

        @include lg-block() {
            display: block;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
        }
    }

}

</style>
