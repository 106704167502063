export const store = () => {
    return window.axios.post('/api/schedule');
}
export const update = (schedule) => {
    return window.axios.put('/api/schedule/' + schedule.id, schedule);
}
export const index = () => {
    return window.axios.get('/api/schedule');
}
export const show = (day) => {
    return window.axios.get('/api/schedule/' + day);
}
export const indexVending = () => {
    return window.axios.get('/api/vending-schedule');
}
export const updateTypeVending = (data) => {
    return window.axios.post('/api/vending-schedule/update-type', data);
}
export const updateVending = (schedule) => {
    return window.axios.put('/api/vending-schedule/' + schedule.id, schedule);
}
export const updateRangeVending = (data) => {
    return window.axios.post('/api/vending-schedule/update-range', data);
}
