<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-img
                v-if="$auth.user().avatar_url"
                class="rounded-circle border-1E5CBA cursor-pointer"
                max-width="50"
                width="50"
                max-height="50"
                height="50"
                v-bind="attrs"
                v-on="on"
                :src="$auth.user().avatar_url"
            ></v-img>
            <v-img
                v-else
                src="/img/user_hero.png"
                class="rounded-circle icon-profile cursor-pointer"
                color="grey lighten-2"
                max-width="40"
                width="40"
                max-height="40"
                height="40"
                v-bind="attrs"
                v-on="on"
            ></v-img>
        </template>
        <v-list>
            <v-list-item :to="{ name: dashboardLink }">
                <v-list-item-title>{{ $t('general.dashboard') }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'dashboard.profile' }">
                <v-list-item-title v-if="$auth.user().name"> {{ $auth.user().name }}</v-list-item-title>
                <v-list-item-title v-else>{{ $t('general.profile') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleLogout">
                <v-list-item-title class="cursor-pointer"
                    >{{ $t('general.logout') }}</v-list-item-title
                >
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "auth-user-dropdown",
    computed: {
        dashboardLink() {
            return this.$auth.user().primary_role_name === "admin"
                ? "admin.index"
                : "dashboard.index";
        },
        auth() {
            return this.$auth;
        }
    },
    data: function() {
        return {};
    },
    methods: {
        handleLogout() {
            this.$auth.logout();
        }
    }
};
</script>

<style scoped>
.icon-profile:hover {
    color: #757575!important
}

.cursor-pointer {
    cursor: pointer
}

.border-1E5CBA {
    border: 2px solid #1E5CBA
}
</style>
