import { store, index, update, destroy } from "@/api/product";

const state = {
    product: {},
    defaultProduct: {
        name: "",
        price: "",
        image: "",
        description: "",
        allergenen: "",
        ingredients: "",
        is_active: 1,
        categories: [],
        variations: []
    },
    products: {}
};

const getters = {
    defaultProduct: state => {
        return state.defaultProduct;
    },
    product: state => {
        return state.product;
    },
    products: state => {
        return state.products;
    }
};

const mutations = {
    setProduct: (state, product) => {
        state.product = product;
    },
    setProducts: (state, products) => {
        state.products = products;
    }
};

const actions = {
    async storeProduct(context, product) {
        await store(product);
    },
    async updateProduct(context, product) {
        await update(product);
    },
    async destroyProduct(context, product) {
        await destroy(product);
    },
    async setProducts({ commit }, { page, itemsPerPage }) {
        let { data } = await index({ page, itemsPerPage });
        commit("setProducts", data);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
