import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/vue-auth'
import 'axios-progress-bar/dist/nprogress.css'
import {loadProgressBar} from 'axios-progress-bar'
import i18n from './i18n'
import FlagIcon from 'vue-flag-icon'
import axios from 'axios'
import { Model } from 'vue-api-query'
import JsonExcel from "vue-json-excel";
import VueMask from 'v-mask';

Vue.use(VueMask);

import _ from 'lodash';
import * as Sentry from "@sentry/vue";
Object.defineProperty(Vue.prototype, '$_', { value: _ });

Vue.config.productionTip = false
require('./bootstrap');
loadProgressBar()
Vue.use(FlagIcon)

Vue.component("downloadExcel", JsonExcel);

// inject global axios instance as http client to Model
Model.$http = axios

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_LARAVEL_DSN,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", process.env.VUE_APP_APP_URL],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
