import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import site from "./site";
import widget from "./widget";
import schedule from "./schedule";
import order from "./order";
import product from "./product";
import category from "./category";
import shop_settings from "./shop_settings";
import dashboard from "./dashboard";
import template from "./template";
import holiday from "./holiday";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        site,
        widget,
        schedule,
        order,
        product,
        category,
        shop_settings,
        dashboard,
        template,
        holiday
    }
});
