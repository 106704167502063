import {
    update,
    store,
    index,
    show,
    indexVending,
    updateTypeVending,
    updateVending,
    updateRangeVending
} from "@/api/schedule";

const state = {
    schedule: [],
    scheduleVending: [],
    type: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
    ],
    scheduleByDay: []
};

const getters = {
    schedule: state => {
        return state.schedule;
    },
    scheduleVending: state => {
        return state.scheduleVending;
    },
    type: state => {
        return state.type;
    },
    scheduleByDay: state => {
        return state.scheduleByDay;
    }
};

const mutations = {
    setSchedule: (state, schedule) => {
        state.schedule = schedule;
    },
    setScheduleVending: (state, schedule) => {
        [0, 1, 2, 3, 4, 5, 6].forEach((number) => {
            let arr = schedule[number];
            if (Array.isArray(arr) && arr.length > 0) {
                schedule[number] = arr.map((item) => {
                    item.is_range = !!(item.from_at && item.end_at);
                    //
                    if (item.is_range && item.available) {
                        state.type[number] = 1
                    }
                    return item;
                });
            }
        });
        state.scheduleVending = schedule
    },
    setScheduleByDay: (state, schedule) => {
        state.scheduleByDay = schedule;
    }
};

const actions = {
    async setSchedule({commit}) {
        let {data} = await index();
        commit("setSchedule", data);
    },
    async setScheduleByDay({commit}, day) {
        let {data} = await show(day);
        commit("setScheduleByDay", data);
    },
    async updateSchedule({commit}, hour) {
        let {data, status} = await update(hour);
        if (status === 200) {
            commit("setSchedule", data);
        } else {
            console.log(state.schedule);
            console.log(data);
        }
    },
    async storeSchedule({commit}) {
        let {data, status} = await store();

        if (status === 200) {
            commit("setSchedule", data);
        } else {
            console.log(state.schedule);
            console.log(data);
        }
    },
    // vending
    async setScheduleVending({commit}) {
        let {data} = await indexVending();
        commit("setScheduleVending", data);
    },
    async updateTypeVending({commit}, payload) {
        let {data} = await updateTypeVending(payload);
        commit("setScheduleVending", data);
    },
    async updateScheduleVending({commit}, hour) {
        let {data, status} = await updateVending(hour);
        if (status === 200) {
            commit("setScheduleVending", data);
        } else {
            console.log(state.schedule);
            console.log(data);
        }
    },
    // eslint-disable-next-line no-unused-vars
    async updateRangeVending({commit}, payload) {
        await updateRangeVending(payload);
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};
