export const store = category => {
    return window.axios.post("/api/category", category);
};

export const index = params => {
    return window.axios.get("/api/category", { params });
};

export const update = category => {
    return window.axios.post("/api/category/" + category.id, category);
};

export const destroy = category => {
    return window.axios.delete("/api/category/" + category.id);
};
