<template>
    <v-app>
        <router-view />
        <saas-snackbar />
    </v-app>
</template>

<script>
import SaasSnackbar from "./components/SaasSnackbar";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "App",
    computed: {
        layout() {
            return `${this.$route.meta.layout || "empty"}-layout`;
        },
        ...mapGetters(["getShopSettings"])
    },
    components: {
        SaasSnackbar
    },
    data: () => ({
        locale: 'us',
    }),
    methods: {
        ...mapActions(['fillSettings']),
        setLocale(locale) {
            this.$i18n.locale = locale
            this.locale = locale
        }
    },
    mounted() {

    }
};
</script>
<style type="text/css">
#nprogress .bar {
    height: 4px !important;
}
</style>
