import { store, index, update, destroy } from "@/api/category";

const state = {
    category: {},
    defaultCategory: { name: "", extra_processing_time: 0 },
    categories: []
};

const getters = {
    defaultCategory: state => {
        return state.defaultCategory;
    },
    category: state => {
        return state.category;
    },
    categories: state => {
        return state.categories;
    }
};

const mutations = {
    setCategory: (state, category) => {
        state.category = category;
    },
    setCategories: (state, categories) => {
        state.categories = categories;
    }
};

const actions = {
    async storeCategory(context, category) {
        await store(category);
    },
    async updateCategory(context, category) {
        await update(category);
    },
    async destroyCategory(context, category) {
        await destroy(category);
    },
    async setCategories({ commit }, { page, itemsPerPage }) {
        let { data } = await index({ page, itemsPerPage });
        commit("setCategories", data);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
