<template>
    <div class="landing">
        <Header />
        <MainSection />
        <Achievements />
        <Benefits />
        <GetStart />
        <Organize />
        <Appointment />
        <Steps />
        <Faq />
        <Footer />
    </div>
</template>

<script>
import Header from "./sections/Header.vue";
import MainSection from "./sections/MainSection.vue";
import Achievements from "./sections/Achievements.vue";
import Benefits from "./sections/Benefits.vue";
import GetStart from "./sections/GetStart.vue";
import Organize from "./sections/Organize.vue";
import Appointment from "./sections/Appointment.vue";
import Steps from "./sections/Steps.vue";
import Faq from "./sections/Faq.vue";
import Footer from "./sections/Footer.vue";

export default {
    components: {
        Header,
        MainSection,
        Achievements,
        Benefits,
        GetStart,
        Organize,
        Appointment,
        Steps,
        Faq,
        Footer
    }
};
</script>

<style>
a {
    text-decoration: none;
}

.container {
    max-width: 1240px;
    margin: 0 auto;
    width: 100%;
}

.landing {
    font-family: "Montserrat", sans-serif;
}
</style>
