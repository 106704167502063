import { index, update } from "@/api/shop_settings"

const state = {
    shop_settings: '',
}

const getters = {
    getShopSettings: (state) => {
        return state.shop_settings
    }
}

const mutations = {
    getApiShopSettings: (state) => {
        index().then(res => {
            state.shop_settings = res.data
        })
    },
    saveApiShopSettings: (state, new_shop_settings) => {
        update(new_shop_settings).then(res => {
            state.shop_settings = res.data
        })
    },
    fillSettings: (state, fill) => {
        state.shop_settings = fill
    },
}

const actions = {
    loadShopSettings: (context) => {
        context.commit('getApiShopSettings')
    },
    updateShopSettings: (context, new_shop_settings) => {
        context.commit('saveApiShopSettings', new_shop_settings)
    },
    fillSettings: (context, fill) => {
        context.commit('fillSettings', fill)
    },
}

export default {
    state, getters, mutations, actions
}
