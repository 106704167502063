export default [
    {
        path: "/dashboard",
        name: "dashboard.index",
        component: () => import("../views/dashboard/Index.vue"),
        meta: {auth: true, title: 'general.dashboard'}
    },
    {
        path: "/dashboard/profile",
        name: "dashboard.profile",
        component: () => import("../views/dashboard/Profile.vue"),
        meta: {auth: true, title: 'general.profile'}
    },
    {
        path: "/dashboard/billing",
        name: "dashboard.billing",
        component: () => import("../views/dashboard/billing/Index.vue"),
        meta: {auth: true, title: 'general.billing'}
    },
    {
        path: "/dashboard/billing/select-plan",
        name: "dashboard.billing.select-plan",
        component: () => import("../views/dashboard/billing/SelectPlan.vue"),
        meta: {auth: true, title: 'general.select-plan'}
    },
    {
        path: "/dashboard/billing/checkout",
        name: "dashboard.billing.checkout",
        component: () => import("../views/dashboard/billing/Checkout.vue"),
        meta: {auth: true, title: 'general.billing-checkout'}
    },
    {
        path: "/dashboard/schedule",
        name: "dashboard.schedule.index",
        component: () => import("../views/dashboard/schedule/Index.vue"),
        meta: {auth: true, title: 'general.choose-working-hours'}
    },
    {
        path: "/dashboard/widget_settings",
        name: "dashboard.widget_settings.index",
        component: () => import("../views/dashboard/widget_settings/Index.vue"),
        meta: {auth: true, title: 'general.build-a-widget'}
    },
    {
        path: "/dashboard/settings",
        name: "dashboard.shop_settings.index",
        component: () => import("../views/dashboard/shop_settings/Index.vue"),
        meta: {auth: true, title: 'general.shop-settings'}
    },
    {
        path: "/dashboard/settings/:id",
        name: "dashboard.shop_settings.update",
        meta: {auth: true}
    },
    {
        path: "/dashboard/orders",
        name: "dashboard.orders.index",
        component: () => import("../views/dashboard/orders/Index.vue"),
        meta: {auth: true, title: 'general.orders'}
    },
    {
        path: "/dashboard/orders/:id",
        name: "dashboard.orders.show",
        component: () => import("../views/dashboard/orders/Show.vue"),
        meta: {auth: true}
    },
    {
        path: "/orders/:client_id/order:id.pdf",
        meta: {auth: true}
    },
    {
        path: "/dashboard/products",
        name: "dashboard.products.index",
        component: () => import("../views/dashboard/products/Index.vue"),
        meta: {auth: true, title: 'general.products'}
    },
    {
        path: "/dashboard/categories",
        name: "dashboard.categories.index",
        component: () => import("../views/dashboard/categories/Index.vue"),
        meta: {auth: true, title: 'general.categories'}
    },
    {
        path: '/dashboard/templates',
        name: 'dashboard.templates.index',
        component: () => import("../views/dashboard/templates/Index.vue"),
        meta: {auth: true, title: 'general.templates'}
    },
    {
        path: '/dashboard/templates/create',
        name: 'dashboard.templates.create',
        component: () => import("../views/dashboard/templates/Create.vue"),
        meta: {auth: true, title: 'general.templates'}
    },
    {
        path: '/dashboard/templates/:id/edit',
        name: 'dashboard.templates.edit',
        component: () => import("../views/dashboard/templates/Edit.vue"),
        meta: {auth: true, title: 'general.templates'}
    },
    {
        path: '/dashboard/notifications',
        name: 'dashboard.notifications.index',
        component: () => import("../views/dashboard/Notifications.vue"),
        meta: {auth: true, title: 'general.notifications'}
    },
    {
        path: "/dashboard/holiday",
        name: "dashboard.holiday.index",
        component: () => import("../views/dashboard/holiday/Index.vue"),
        meta: {auth: true, title: 'general.holiday'}
    },
];
