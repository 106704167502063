<template>
    <main class="main-section white--text">
        <div class="container">
            <div class="main-section-fon"></div>
            <div class="main-section__box">
                <h1 class="main-section__title main-section__title_h1">
                    {{ $t('general.seamless-storefront-solution') }}
                </h1>
                <p class="main-section__title main-section__title_desc">
                    {{ $t('general.your-hassle-free-integrate') }}
                </p>
                <div class="main-section__btn-wrap white--text">
                    <router-link class="main-section__btn"
                                 v-if="!$auth.user()"
                                 :to="{ name: 'sign-up' }">
                        {{ $t('general.get-started-for-free') }}
                    </router-link>
                    <router-link class="main-section__btn"
                                 v-if="$auth.user()"
                                 :to="{ name: 'dashboard.index' }">
                        {{ $t('general.dashboard') }}
                    </router-link>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

export default {
    name: "landing_page",
    components: {},
    data: () => ({}),
    methods: {
        init() {

        },
    },
    mounted() {
        this.init();
    },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/mixins";

.main-section-fon {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / .2);
    display: none;
    @media (max-width: 767px) {
        display: block;
    }
}

.main-section {
    position: relative;
    background: url(/img/headline.jpg) top no-repeat;
    background-size: cover;
    height: 656px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &__box {
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        padding-left: 16px;
    }

    &__title {
        &_h1 {
            max-width: 600px;
            font-weight: 700;
            font-size: 36px;
            line-height: 44px;

            @media (max-width: 767px) {
                font-size: 24px;
                max-width: 350px;
                line-height: 29px
            }
        }

        &_desc {
            font-weight: 200;
            margin: 40px 0;
            font-size: 20px;
            line-height: 25px;
            max-width: 550px;
            @media (max-width: 767px) {
                margin: 16px 0;
                font-size: 16px;
            }
        }
    }

    .main-section__btn-wrap {
        //min-width: 100%;
        //text-align: center;
        margin-top: 130px;

        .main-section__btn {
            background: #1e5cba;
            border-radius: 10px;
            padding: 20px 80px;
            font-size: 24px;
            color: #fff;
            transition: background .3s;
            margin: 15px 5px 5px 5px;

            @include sm-block() {
                padding: 10px 40px;
                font-size: 24px;
            }

            &:hover {
                background: #154286;
                transition: all .3s;
            }
        }

        @include sm-block() {
            margin-top: 60px;
        }
    }

}

</style>
