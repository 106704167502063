export const store = template => {
    return window.axios.post("/api/templates", template);
};

export const index = params => {
    return window.axios.get("/api/templates", {params});
};

export const update = template => {
    return store(template)
};

export const destroy = template => {
    return window.axios.delete("/api/templates/" + template.type);
};

export const show = template => {
    return window.axios.get("/api/templates/" + template.type);
};

export const send_email = data => {
    return window.axios.post("/api/templates/send-email", data);
}

export const getUnboundTemplateTypes = () => {
    return window.axios.get("/api/templates/unbound-types");
}

export const getContextForTemplateType = type => {
    return window.axios.get("/api/templates/context/" + type);
}

export const preview = (type, body) => {
    return window.axios.post("/api/templates/preview", {type, body});
}

