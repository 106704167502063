import { store, index, update, destroy } from "@/api/holiday";

const state = {
    holiday: {
        date: ""
    },
    defaultHoliday: {
        date: ""
    },
    holidays: []
};

const getters = {
    defaultHoliday: state => {
        return state.defaultHoliday;
    },
    holiday: state => {
        return state.holiday;
    },
    holidays: state => {
        return state.holidays;
    }
};

const mutations = {
    setHoliday: (state, holiday) => {
        state.holiday = holiday;
    },
    setHolidays: (state, holidays) => {
        state.holidays = holidays;
    }
};

const actions = {
    async storeHoliday(context, holiday) {
        await store(holiday);
    },
    async updateHoliday(context, holiday) {
        await update(holiday);
    },
    async destroyHoliday(context, holiday) {
        await destroy(holiday);
    },
    async setHolidays({ commit }) {
        let { data } = await index();
        commit("setHolidays", data);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
