import { update, store, show } from "@/api/widget";

const state = {
    widget: {},
};

const getters = {
    widget: state => {
        return state.widget
    },
};

const mutations = {
    setWidget: (state, widget) => {
        state.widget = widget
    },
};

const actions = {
    async setWidget({ commit }) {
        let { data } = await show();
        commit('setWidget', data)
    },

    async updateWidget({ commit, state }) {
        let { data, status } = await update(state.widget)
        if (status == 200) {
            commit('setWidget', data)
        } else {
            console.log(state.widget)
            console.log(data)
        }
    },

    async storeWidget({commit}) {
        let { data, status } = await store();

        if (status == 200) {
            commit('setWidget', data)
        } else {
            console.log(state.widget)
            console.log(data)
        }
    }
};

export default {
    state, getters, mutations, actions
}
