<template>
    <div class="container">
        <h2 class="faq_title">{{ $t('general.faq') }}</h2>
        <ul class="accordion">
            <li class="accordion__item" v-for="(item, i) in items" :key="i">
                <div
                    class="accordion__trigger"
                    :class="{ accordion__trigger_active: item.active }"
                    @click="open(i)"
                >
                    <b> {{ $t(item.label) }} </b>
                    <span style="font-weight: 700; font-size: 24px;">{{ item.active ? "-" : "+" }}</span>
                </div>

                <transition
                    name="accordion"
                    @enter="start"
                    @after-enter="end"
                    @before-leave="start"
                    @after-leave="end"
                >
                    <div class="accordion__content" v-show="item.active">
                        <ul>
                            {{
                                $t(item.text)
                            }}
                        </ul>
                    </div>
                </transition>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            index: null,
            items: [
                {
                    label: 'general.what-types-of-business',
                    text: 'general.our-services-are-designed',
                    active: false
                },
                {
                    label: 'general.how-do-i-get',
                    text: 'general.getting-started-is-easy',
                    active: false
                },
                {
                    label: 'general.what-sets-your-company',
                    text: 'general.we-pride-ourselves',
                    active: false
                },
                {
                    label: 'general.is-your-software',
                    text: 'general.yes-our-software',
                    active: false
                },
                {
                    label: 'general.how-can-i-get',
                    text: 'general.were-here-to-help',
                    active: false
                }
            ]
        };
    },
    methods: {
        open(index) {
            if (this.index === index) {
                this.index = null;
                this.items[index].active = false;
            } else {
                this.index = index;
                this.items[index].active = true;
            }
        },
        start(el) {
            el.style.height = el.scrollHeight + "px";
        },
        end(el) {
            el.style.height = "";
        }
    }
};
</script>

<style lang="scss" scoped>
.accordion {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 54px;

    &__item:last-child {
        border-bottom: none;
    }
}

.accordion__item {
    cursor: pointer;
    padding: 10px 20px 10px 40px;
    border-bottom: 1px solid #ebebeb;
    position: relative;
}

.accordion__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-enter-active,
.accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
    height: 0 !important;
    opacity: 0;
}

.faq_title {
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    margin-top: 160px;
}
</style>
