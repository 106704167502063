export default [
    {
        path: '/admin',
        name: 'admin.index',
        component: () => import('../views/admin/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/profile',
        name: 'admin.profile',
        component: () => import('../views/admin/Profile.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users',
        name: 'admin.users.index',
        component: () => import('../views/admin/users/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users/:id',
        name: 'admin.users.show',
        component: () => import('../views/admin/users/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/orders',
        name: 'admin.orders.index',
        component: () => import('../views/admin/orders/Index.vue'),
        meta: {
            auth: true,
            role: 'admin'
        }
    },
    {
        path: '/admin/orders/:id',
        name: 'admin.orders.show',
        component: () => import('../views/admin/orders/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/orders/pdf',
        name: 'admin.orders.pdf',
        component: () => import('../views/admin/orders/Pdf.vue'),
        meta: {
            auth: 'admin'
        }
    },
];
