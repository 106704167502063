import {destroy, index, send_email, show, store, update} from "@/api/template";
import {getContextForTemplateType, getUnboundTemplateTypes} from "../api/template";

const state = {
    template: {
        type: "",
        subject: "",
        body: ""
    },
    defaultTemplate: {
        type: "",
        subject: "",
        body: ""
    },
    templates: {},
    template_data: '',
    unboundTypes: [],
    context: {}
};

const getters = {
    defaultTemplate: state => {
        return state.defaultTemplate;
    },
    template: state => {
        return state.template;
    },
    templates: state => {
        return state.templates;
    },
    unboundTypes: state => {
        return state.unboundTypes;
    },
    context: state => {
        return state.context;
    }
};

const mutations = {
    setTemplate: (state, template) => {
        state.template = template;
    },
    setTemplates: (state, templates) => {
        state.templates = templates;
    },
    setUnboundTypes: (state, types) => {
        state.unboundTypes = types;
    },
    setContext(state, context) {
        state.context = context;
    }
};

const actions = {
    async storeTemplate(context, template) {
        await store(template);
    },
    async updateTemplate(context, template) {
        await update(template);
    },
    async destroyTemplate(context, template) {
        await destroy(template);
    },
    async setTemplates({ commit }) {
        let { data } = await index();
        commit("setTemplates", data);
    },
    async getTemplate({ commit }, template) {
        let { data } = await show(template);
        commit("setTemplate", data);
    },
    async sendEmail(context, data) {
        return await send_email(data);
    },
    async fetchUnboundTemplateTypes({ commit }) {
        let { data } = await getUnboundTemplateTypes();
        commit("setUnboundTypes", data);
    },
    async fetchContextForTemplateType({ commit }, type) {
        let { data } = await getContextForTemplateType(type);
        commit("setContext", data);
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};
