<template>
    <header>
        <div class="main-header-bg">
            <div class="container main-header">
                <div class="main-header__phone">
                    <a :href="'tel:'+contactTel" class="white--text main-header__phone-link">
                        <div class="main-header__icon-phone">
                            <img src="/icons/call.svg" alt="#">
                        </div>
                        <span class="main-header__phone-num">{{ contactTel }}</span>
                    </a>
                </div>

                <div class="main-header__mail">
                    <a :href="'mailto:'+contactEmail" class="white--text main-header__mail-link">
                        <div class="main-header__icon-mail">
                            <img src="/icons/sms.svg" alt="#">
                        </div>
                        <span class="main-header__mail-text">{{ contactEmail }}</span>
                    </a>
                </div>

                <ul class="main-header__media-list">
                    <li class="main-header__media-item">
                        <a href="https://www.facebook.com/NomadicSoftLLC" class="main-header__media-link">
                            <img src="/icons/facebook.svg" alt="#" class="main-header__media-icon">
                        </a>
                    </li>
                    <li class="main-header__media-item">
                        <a href="https://www.linkedin.com/company/nomadic-soft" class="main-header__media-link">
                            <img src="/icons/linkedin.svg" alt="#" class="main-header__media-icon">
                        </a>
                    </li>
                    <li class="main-header__media-item">
                        <a href="https://twitter.com/nomadicsoftio" class="main-header__media-link">
                            <img src="/icons/twitter.svg" alt="#" class="main-header__media-icon">
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="second-header container">
            <router-link :to="{name: 'landing'}" class="second-header__logo-wrap">
                <img class="second-header__logo" src="/icons/logo.svg" alt="logo"/>
            </router-link>
            <ul class="second-header__links" v-if="show_mob_menu">
                <li class="second-header__item">
                    <router-link class="black--text second-header__item-link"
                                 :to="{name: 'about'}"
                    >{{ $t('general.about-us') }}
                    </router-link>
                </li>
                <li class="second-header__item">
                    <router-link class="black--text second-header__item-link"
                                 :to="{ name: 'demo' }">{{ $t('general.demo') }}
                    </router-link>
                </li>
                <!--                <li class="second-header__item">-->
                <!--                    <a class="black&#45;&#45;text second-header__item-link" href="">{{ $t('general.clients') }}</a>-->
                <!--                </li>-->
                <!--                <li class="second-header__item" v-if="!$auth.user()">-->
                <!--                    <router-link class="black&#45;&#45;text second-header__item-link"-->
                <!--                                 :to="{ name: 'sign-up' }">{{ $t('general.free-trial') }}-->
                <!--                    </router-link>-->
                <!--                </li>-->
                <li class="second-header__item" v-if="$auth.user()">
                    <router-link class="black--text second-header__item-link"
                                 :to="{ name: 'dashboard.index' }">{{ $t('general.dashboard') }}
                    </router-link>
                </li>
                <!--                <li class="second-header__item">-->
                <!--                    <a class="black&#45;&#45;text second-header__item-link" href="">{{ $t('general.blog') }}</a>-->
                <!--                </li>-->
                <li class="second-header__item">
                    <router-link class="black--text second-header__item-link"
                                 :to="{ name: 'contacts' }">{{ $t('general.contact-us') }}
                    </router-link>
                </li>
            </ul>
            <div :class="!$auth.user() ? 'second-header__buttons' : 'second-header__locale'" v-if="show_mob_menu">
                <router-link v-if="!$auth.user()" :to="{ name: 'sign-up' }"
                             class="black--text second-header__btn-signUp">
                    {{ $t('general.register') }}
                </router-link>
                <router-link v-if="!$auth.user()" :to="{ name: 'login' }" class="black--text second-header__btn-signIn">
                    {{ $t('general.sign-in') }}
                </router-link>
                <div class="d-flex">
                    <a href="#" @click="setLocale('us')" class="mr-2"
                       :class="this.$i18n.locale === 'us' ? 'border-bottom' : ''">
                        <flag iso="us"></flag>
                    </a>
                    <a href="#" @click="setLocale('nl')" :class="this.$i18n.locale === 'nl' ? 'border-bottom' : ''">
                        <flag iso="nl"></flag>
                    </a>
                </div>
                <auth-user-dropdown v-if="$auth.user() && Object.keys(this.$auth.user()).length > 0"/>
            </div>

            <div class="btn-mob-show-menu"
                 @click="show_mob_menu = !show_mob_menu"
                 :class="{active: show_mob_menu}">
                <span class="btn-mob-show-menu__item btn-mob-show-menu__item-1"></span>
                <span class="btn-mob-show-menu__item btn-mob-show-menu__item-2"></span>
                <span class="btn-mob-show-menu__item btn-mob-show-menu__item-3"></span>
            </div>
        </div>
    </header>
</template>

<script>
import AuthUserDropdown from "@/components/AuthUserDropdown";

export default {
    components: {AuthUserDropdown},
    data: () => ({
        show_mob_menu: true,
        width_screen: null,
    }),
    computed: {
        contactEmail: function () {
            return process.env.APP_CONTACT_EMAIL || "hello@nomadicsoft.io";
        },
        contactTel: function () {
            return process.env.APP_CONTACT_TEL || "+1 (844) 961-4030";
        }
    },
    methods: {
        setLocale(locale) {
            this.$i18n.locale = locale
        },
        updateWidthScreen() {
            this.width_screen = window.innerWidth
            this.show_mob_menu = this.width_screen >= 1200;
        },
    },
    created() {
        this.updateWidthScreen()
        window.addEventListener('resize', this.updateWidthScreen)
    },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/mixins";

header {
    background: white;
        .main-header-bg {
        background: #232020;
    }
}

.main-header {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;

    .main-header__phone {
        padding: 3px;

        .main-header__phone-link {
            display: flex;
            align-items: center;
            justify-content: center;

            .main-header__icon-phone {
                margin-right: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .main-header__phone-num {
                @include lg-block() {
                    display: none;
                }
            }
        }
    }

    .main-header__mail {
        padding: 3px;

        @include lg-block() {
            margin-right: auto;
        }

        .main-header__mail-link {
            display: flex;
            align-items: center;
            justify-content: center;

            .main-header__icon-mail {
                margin-right: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .main-header__mail-text {
                @include lg-block() {
                    display: none;
                }
            }
        }
    }

    .main-header__media-list {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px;

        .main-header__media-item {
            list-style: none;
            padding: 0 5px;
            margin: 0 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            .main-header__media-link {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.second-header {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;
    background: white !important;

    @include lg-block() {
        align-items: flex-start;
        justify-content: space-between;
    }

    .second-header__logo-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__links {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;

        @include lg-block() {
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            background: #fff;
            position: absolute;
            bottom: -185px;
            right: 0;
            padding: 5px 10px;
            z-index: 10;
            width: 180px;
        }

        .second-header__item {
            list-style: none;
            padding: 0 5px;
            margin: 0 15px;

            @include lg-block() {
                padding: 5px 0;
                margin: 5px 0;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;

        @include lg-block() {
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            padding: 5px 10px;
            position: absolute;
            bottom: -360px;
            right: 0;
            background: #fff;
            z-index: 10;
            width: 180px;
            box-shadow: 5px 15px 10px rgba(0, 0, 0, .5);
        }

        .second-header__btn-signUp {
            border: 1px solid #000;
            padding: 6px 37px;
            margin-right: 47px;

            @include lg-block() {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        .second-header__btn-signIn {
            margin-right: 20px;

            @include lg-block() {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }

    &__locale {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 150px;

        @include lg-block() {
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            padding: 5px 10px;
            position: absolute;
            bottom: -283px;
            right: 0;
            background: #fff;
            z-index: 10;
            width: 180px;
            height: 100px;
            box-shadow: 5px 15px 10px rgba(0, 0, 0, .5);
        }
    }
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, .42);
    padding-bottom: 3px;
}

.btn-mob-show-menu {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background: #232020;
    cursor: pointer;
    transition: all .3s;
    display: none;

    @include lg-block() {
        display: inline-block;
    }

    &:hover {
        cursor: pointer;
        background: #000000;
    }

    .btn-mob-show-menu__item {
        display: inline-block;
        width: 24px;
        height: 2px;
        background: #fff;
        position: absolute;
    }

    .btn-mob-show-menu__item-1 {
        top: 8px;
        left: 3px;
    }

    .btn-mob-show-menu__item-2 {
        top: 14px;
        left: 3px;
    }

    .btn-mob-show-menu__item-3 {
        top: 20px;
        left: 3px;
    }
}

.btn-mob-show-menu.active {
    transition: all .3s;

    .btn-mob-show-menu__item-1 {
        top: 14px;
        left: 3px;
        transform: rotate(45deg);
    }

    .btn-mob-show-menu__item-2 {
        display: none;
    }

    .btn-mob-show-menu__item-3 {
        top: 14px;
        left: 3px;
        transform: rotate(-45deg);
    }
}
</style>
