export const store = product => {
    return window.axios.post("/api/product", product);
};

export const index = params => {
    return window.axios.get("/api/product", { params });
};

export const update = product => {
    return window.axios.put("/api/product/" + product.id, product);
};

export const destroy = product => {
    return window.axios.delete("/api/product/" + product.id);
};
