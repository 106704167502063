export const update = order => {
    return window.axios.put("/api/order/" + order.id, order);
};
export const index = params => {
    return window.axios.get("/api/order", { params });
};
export const show = order => {
    return window.axios.get("/api/order/" + order.id);
};
export const destroy = order => {
    return window.axios.delete("/api/order/" + order.id);
};
export const get_cart = order => {
    return window.axios.get("/api/order/" + order.id);
};
export const viewed = params => {
    return window.axios.post("/api/order/viewed", { ...params });
}
