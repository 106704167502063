import { getOrders } from "@/api/dashboard";

const state = {
    orders: '',
}


const getters = {
    getOrders: (state) => {
        return state.orders
    }
}

const mutations = {
    getApiOrders: (state) => {
        getOrders().then(res => {
            state.orders = res.data
        })
    },
}

const actions = {
    loadOrders: (context) => {
        context.commit('getApiOrders')
    },
}

export default {
    state, getters, mutations, actions
}
